import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const I18N_LANGUAGES = ['de', 'en', 'es', 'fr', 'it', 'pl'] as const;
export type I18nLanguage = (typeof I18N_LANGUAGES)[number];

@Pipe({
  name: 'onyxLanguage',
  standalone: true,
})
export class OnyxLanguagePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: Record<I18nLanguage, any>): string {
    const currentLanguage = (this.translateService.currentLang ??
      this.translateService.defaultLang) as I18nLanguage;

    return value[currentLanguage] ?? '';
  }
}
